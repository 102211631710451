import React, { useEffect, useState } from "react";

import { currentUserVar, useReactiveVar } from "@apollo";

// Misc Imports
import { OnlineScreenFilters, StaticOffer, Theme } from "@app-types";
import { useOffers, useSocialAuthListener, useUpdateUser } from "@hooks";
import { makeStyles } from "@styles";
import { filterOffers, ModalHelper } from "@utils";

// Component imports
import {
  ConfettiComponent,
  FiltersHeader,
  OfferCardsContainer,
  OnlineHeader,
  SocialLoadingCover,
} from "@components";
import { Grid } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    flexGrow: 1,
    width: "80%",
    maxWidth: 1400,
    minHeight: "100vh",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  cardContainer: {
    width: "100%",
    maxWidth: 1400,
    marginTop: 5,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 36,
  },
}));

const OnlineScreen = ({ location }) => {
  const classes: any = useStyles({});

  const user = useReactiveVar(currentUserVar);
  const { updateUser } = useUpdateUser({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Social Auth code
  const socialAuth: boolean =
    !!(location?.search?.substring(0, 6) === "?code=") || false;
  useSocialAuthListener();
  useEffect(() => {
    if (socialAuth) ModalHelper.open({ modalType: "socialAuthModal" });
  }, []);

  // Offers logic
  const initialFiltersState: OnlineScreenFilters = {
    category: "ALL",
    order: "Featured",
    display: "Cards",
    searchString: "",
  };
  const [filters, setFilters] = useState<OnlineScreenFilters>(
    initialFiltersState,
  );

  const offers: StaticOffer[] = useOffers();
  const filteredOffers: StaticOffer[] = filterOffers(offers, filters);

  // Success Modal Logic
  const [confetti, setConfetti] = useState<boolean>(false);

  useEffect(() => {
    if (!!user) {
      const { didLogin: userDidLogin } = user;

      if (userDidLogin) return;

      const onOpenSuccessModal = (): void => {
        setConfetti(true);
        ModalHelper.open({
          modalType: "success",
          modalProps: {
            onClose: onSuccessModalDismissed,
          },
        });
      };

      const onSuccessModalDismissed = (): void => {
        setConfetti(false);
        ModalHelper.close();

        updateUser({ didLogin: true });
      };

      if (!userDidLogin) {
        setConfetti(true);
        onOpenSuccessModal();
      }
    }
  }, [user]);

  return (
    <div className={classes.pageContent}>
      {confetti && <ConfettiComponent confetti={true} />}
      <Grid item xs={12}>
        <OnlineHeader />
      </Grid>
      <FiltersHeader filters={filters} setFilters={setFilters} />
      <Grid className={classes.cardContainer}>
        <OfferCardsContainer
          display={filters.display}
          offers={filteredOffers}
          userId={user?.id}
        />
      </Grid>
    </div>
  );
};

export default OnlineScreen;
